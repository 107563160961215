import React, { useRef } from "react"
import Slider from "react-slick"

import PropTypes from "prop-types"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SlideShow = ({ images, currentSlide, setCurrentSlide }) => {
  const slider = useRef(null)
  const settings = {
    afterChange: (current) => {
      setCurrentSlide(current)
    },
    dots: false,
    infinite: true,
    row: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          fade: true,
        },
      },
    ],
  }
  const prevSlide = () => {
    slider.current.slickPrev()
  }
  const nextSlide = () => {
    slider.current.slickNext()
  }

  return (
    <div
      className="slideshow"
      onMouseEnter={() => {
        document.querySelector(".cursor").style.display = "none"
      }}
      onMouseLeave={() => {
        document.querySelector(".cursor").style.display = "block"
      }}
    >
      <div
        className="slideshow__arrow slideshow__previous-arrow"
        onClick={prevSlide}
        aria-hidden="true"
      ></div>
      <Slider {...settings} ref={slider}>
        {images.map((image, index) => (
          <div key={index}>
            <div className="slideshow__image">
              <img
                src={image.image.localFile.childImageSharp.original.src}
                alt={image.image.altText}
              />
            </div>
          </div>
        ))}
      </Slider>
      <div
        className="slideshow__arrow slideshow__next-arrow"
        onClick={nextSlide}
        aria-hidden="true"
      ></div>
    </div>
  )
}

SlideShow.propTypes = {
  currentSlide: PropTypes.number,
  images: PropTypes.array,
  setCurrentSlide: PropTypes.func,
}

export default SlideShow
