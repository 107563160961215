import React, { useState, useRef } from "react"

import { useStaticQuery, graphql } from "gatsby"

import Link from "../utils/link"

import pdf from "../assets/images/DanHocking-BookingInformation.pdf"

import axios from "axios"
import PropTypes from "prop-types"

const Info = ({ description, clients }) => {
  const data = useStaticQuery(graphql`
    {
      wpPost(title: { eq: "Contact Details" }) {
        acfcontactdetails {
          email
          instagram
          phonenumber
        }
      }
    }
  `)

  const contact = data.wpPost.acfcontactdetails

  const formEl = useRef(null)

  const [nonEmpty, setNonEmpty] = useState(true)
  const [validEmail, setValidEmail] = useState(true)

  // to this - common understanding
  const [state, setState] = useState({
    name: "",
    email: "",
  })

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const checkEmailValidity = () => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        state.email
      )
    ) {
      setValidEmail(true)
      return true
    }
    setValidEmail(false)
    return false
  }

  const checkValidity = (e) => {
    e.preventDefault()
    setValidEmail(true)
    if (state.name === "" || state.email === "") {
      setNonEmpty(false)
    } else {
      setNonEmpty(true)
      const emailValidity = checkEmailValidity()
      if (emailValidity && nonEmpty) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "booking_form",
            ...state,
          }),
        })
          .then(() => {
            window && window.open(pdf, "_blank").focus()
          })
          .catch((error) => console.log(error))
      }
    }
  }

  const props = {
    ref: formEl,
    name: "booking_form",
    className: "info__form",
    onSubmit: checkValidity,
    "data-netlify": "true",
    "data-netlify-honeypot": "bot-field",
  }

  return (
    <div className="info">
      <div className="info__section">
        <div className="info__title">{description}</div>
        <div className="info__clients">
          <div>Clients include</div>
          <div className="info__clients__names">
            {clients.map((client, index) => (
              <div className="info__clients__names_name" key={index}>
                {client.clientname}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="info__section">
        <div className="info__address">
          <div>
            <a href={`tel:${contact.phonenumber}`}>{contact.phonenumber} </a>
          </div>
          <div>
            <a href={`mailto:${contact.email}`}> {contact.email}</a>
          </div>
          <div>
            <a
              href={`https://instagram.com/${contact.instagram}`}
              target="_blank"
              rel="noreferrer"
            >
              @{contact.instagram}
            </a>
          </div>
        </div>

        <form {...props}>
          <input
            type="text"
            name="name"
            placeholder="Your name"
            onChange={(e) =>
              setState({
                ...state,
                name: e.target.value,
              })
            }
            required
          />
          <input
            title="Enter a valid email"
            type="email"
            placeholder="Your email"
            name="email"
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            required
          />
          <button type="submit">Booking Info</button>
        </form>
      </div>
      <div className="info__footer">
        <div>
          design <Link to="https://atollon.com.au">atollon</Link>
        </div>
        <div>© 2021 Dan Hocking</div>
      </div>
    </div>
  )
}

Info.propTypes = {
  clients: PropTypes.array,
  description: PropTypes.string,
}

export default Info
