import React from "react"

import PropTypes from "prop-types"

const GalleryViewHeader = ({ setShowGallery }) => {
  return (
    <header className="header">
      <div
        className="header__logo"
        aria-hidden="true"
        onClick={() => {
          setShowGallery(false)
        }}
      >
        <img src="/images/logo.svg" alt="" />
      </div>
      <nav className="header__nav">
        <div
          className="header__toggle-btn"
          aria-hidden="true"
          onKeyDown={() => {}}
          onClick={() => {
            setShowGallery(false)
          }}
        ></div>
      </nav>
    </header>
  )
}

const GalleryView = ({
  image,
  images,
  addressPlace,
  addressName,
  setShowGallery,
}) => {
  return (
    <div className="galleryview">
      <GalleryViewHeader setShowGallery={setShowGallery} />
      {images.length !== 0 ? (
        images.map((image, index) => (
          <div key={index}>
            <img
              src={image.image.localFile.childImageSharp.original.src}
              alt=""
              className="galleryview__image"
            />
            <div className="galleryview__detail">
              {index === 0 ? (
                <div className="galleryview__address">
                  <p>{addressName}</p>
                  <p>{addressPlace}</p>
                </div>
              ) : null}
              <div className="galleryview__image-no">
                {index + 1} / {images.length}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="galleryview__single-image">
          <img
            src={image.localFile.childImageSharp.original.src}
            alt=""
            className="galleryview__image"
          />
          <div className="galleryview__detail">
            <div className="galleryview__address">
              <p>{addressName}</p>
              <p>{addressPlace}</p>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          images.length === 0
            ? "galleryview__back-btn galleryview__back-btn__image-button"
            : "galleryview__back-btn"
        }
        aria-hidden="true"
        onClick={() => {
          setShowGallery(false)
        }}
      >
        <img src="/images/arrowup.svg" alt="" />
        <p>Back</p>
      </div>
    </div>
  )
}

GalleryViewHeader.propTypes = {
  setShowGallery: PropTypes.func,
}

GalleryView.propTypes = {
  addressName: PropTypes.string,
  addressPlace: PropTypes.string,
  image: PropTypes.object,
  images: PropTypes.array,
  setShowGallery: PropTypes.func,
}

export default GalleryView
