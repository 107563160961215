import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import SEO from "../components/seo"
import RenderBlock from "../utils/render-block"

const PageTemplate = ({ data }) => {
  return (
    <>
      <SEO
        title={data.wpPage.seo.title || data.wpPage.title}
        path={`/${data.wpPage.slug}`}
      />

      {data.wpPage &&
        data?.wpPage?.acf?.contentBlock?.length &&
        data.wpPage.acf.contentBlock.map((el, i) => {
          return RenderBlock(el.fieldGroupName, el, i, "page")
        })}
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      slug
      title
      seo {
        title
        metaDesc
      }
      acf {
        contentBlock {
          ... on WpPage_Acf_ContentBlock_ImageContainer {
            addressName
            addressPlace
            image {
              localFile {
                childImageSharp {
                  original {
                    height
                    src
                    width
                  }
                }
              }
            }
            align
            fieldGroupName
            showarrow
          }
          ... on WpPage_Acf_ContentBlock_SlideshowContainer {
            addressName
            addressPlace
            images {
              image {
                localFile {
                  childImageSharp {
                    original {
                      height
                      src
                      width
                    }
                  }
                }
              }
            }
            align
            fieldGroupName
          }
          ... on WpPage_Acf_ContentBlock_Info {
            description
            fieldGroupName
            clients {
              clientname
            }
          }
          ... on WpPage_Acf_ContentBlock_Indexpage {
            fieldGroupName
            contactlink {
              title
              url
            }
            gallery {
              addressName
              addressPlace
              fieldGroupName
              image {
                localFile {
                  childImageSharp {
                    original {
                      height
                      src
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

PageTemplate.propTypes = {
  data: PropTypes.object,
}

export default PageTemplate
