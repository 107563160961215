import React, { useState } from "react"

import PropTypes from "prop-types"

const IndexBlock = ({ imagePath, address, setModalData, index }) => {
  const [ishover, setIshover] = useState(false)

  return (
    <div
      onMouseEnter={() => {
        setIshover(true)
      }}
      onMouseLeave={() => {
        setIshover(false)
      }}
      aria-hidden="true"
      onClick={() => {
        setModalData(index)
      }}
      className=" indexblock"
    >
      <div className="rellax">
        <div className=" indexblock__image ">
          <img src={imagePath} alt="" />
        </div>
        <div
          className={
            ishover
              ? "indexblock__address indexblock__address__visible"
              : "indexblock__address"
          }
        >
          <div>{address.addressName}</div>
          <div>{address.addressPlace}</div>
        </div>
      </div>
    </div>
  )
}

IndexBlock.propTypes = {
  address: PropTypes.object,
  imagePath: PropTypes.string,
  index: PropTypes.number,
  setModalData: PropTypes.func,
}

export default IndexBlock
