import React from "react"

import ImageContainer from "../components/ImageContainer"
import IndexPage from "../components/IndexPage"
import Info from "../components/Info"

const RenderBlock = (param, el, i, postType) => {
  const block = {
    page_Acf_ContentBlock_ImageContainer: (element, index) => (
      <ImageContainer key={index} {...element} />
    ),
    page_Acf_ContentBlock_Indexpage: (element, index) => (
      <IndexPage key={index} {...element} />
    ),
    page_Acf_ContentBlock_Info: (element, index) => (
      <Info key={index} {...element} />
    ),
    page_Acf_ContentBlock_SlideshowContainer: (element, index) => (
      <ImageContainer key={index} {...element} />
    ),
  }[param]
  if (!block) return null
  return block(el, i)
}

export default RenderBlock
