import React, { useState } from "react"

import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import GalleryView from "./GalleryView"
import SlideShow from "./SlideShow"

const ImageContainer = ({
  image,
  images = [],
  addressPlace,
  addressName,
  align,
  showarrow,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [showGallery, setShowGallery] = useState(false)

  return (
    <div>
      {showGallery ? (
        <GalleryView
          image={image}
          images={images}
          addressPlace={addressPlace}
          addressName={addressName}
          setShowGallery={setShowGallery}
        />
      ) : null}
      <Fade>
        <div
          className="contentblock"
          onClick={() => {
            if (window.innerWidth > 992) {
              return
            }
            setShowGallery(true)
          }}
          aria-hidden="true"
        >
          <div
            className={
              align === "left"
                ? "contentblock__designs"
                : "contentblock__designs contentblock__designs__reverse"
            }
          >
            <div
              className="contentblock__designs__image"
              style={{
                maxWidth:
                  images.length !== 0
                    ? images[0].image.localFile.childImageSharp.original.width
                    : "auto",
                width: images.length !== 0 ? "100%" : "auto",
              }}
            >
              {images.length !== 0 ? (
                <SlideShow
                  images={images}
                  setCurrentSlide={setCurrentSlide}
                  currentSlide={currentSlide}
                />
              ) : (
                <img
                  src={image.localFile.childImageSharp.original.src}
                  alt=""
                />
              )}
            </div>
            <div
              className="contentblock__designs__address"
              style={{
                marginBottom: images.length !== 0 ? "25px" : "50px",
              }}
            >
              <div className="contentblock__designs__address_name">
                {images.length !== 0 ? (
                  <div>
                    {currentSlide + 1} / {images.length}
                  </div>
                ) : null}
                {addressName}
              </div>
              <div>{addressPlace}</div>
            </div>
          </div>
          {showarrow ? (
            <div className="contentblock__arrow-icon">
              <img src="/images/arrowdown.svg" id="arrow" alt="" />
            </div>
          ) : null}
        </div>
      </Fade>
    </div>
  )
}

ImageContainer.propTypes = {
  addressName: PropTypes.string,
  addressPlace: PropTypes.string,
  align: PropTypes.string,
  image: PropTypes.object,
  images: PropTypes.array,
  showarrow: PropTypes.bool,
}

export default ImageContainer
