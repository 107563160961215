import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Rellax from "rellax"

import Link from "../utils/link"
import IndexBlock from "./IndexBlock"

const IndexPage = (galleryData) => {
  const [modalData, setModalData] = React.useState(null)
  const [mobileView, setMobileView] = React.useState(false)
  const galleryListsArray = [...galleryData.gallery]
  let galleryLists = []
  for (let index = 0; index < 12; index++) {
    galleryLists = [...galleryListsArray, ...galleryLists]
  }

  const data = useStaticQuery(graphql`
    {
      wpPost(title: { eq: "Contact Details" }) {
        acfcontactdetails {
          email
          instagram
          phonenumber
        }
      }
    }
  `)

  const contact = data.wpPost.acfcontactdetails
  const gallery = React.useRef(null)

  React.useEffect(() => {
    setMobileView(window.innerWidth < 992)
    const rellax = new Rellax(".rellax", {
      center: true,
      horizontal: false,
      vertical: true,
      wrapper: null,
    })
  }, [])

  return (
    <div className="indexpage">
      <div
        className={
          modalData !== null
            ? "indexpage__modal "
            : "indexpage__modal indexpage__modal__hidden"
        }
        aria-hidden="true"
        onClick={() => {
          setModalData(null)
        }}
      >
        {" "}
        <img
          className="indexpage__modal__rightarrow"
          src="/images/arrowleft.png"
          alt=""
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation()
            setModalData(
              modalData === 0 ? galleryLists.length - 1 : modalData - 1
            )
          }}
        />
        <img
          className="indexpage__modal__leftarrow"
          src="/images/arrowright.png"
          alt=""
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation()
            setModalData(
              modalData === galleryLists.length - 1 ? 0 : modalData + 1
            )
          }}
        />
        <img
          src={
            galleryLists[modalData]?.image.localFile.childImageSharp.original
              .src
          }
          className="indexpage__modal__image"
          alt=""
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
        <div className="indexpage__modal__address">
          <div>{galleryLists[modalData]?.addressName}</div>
          <div>{galleryLists[modalData]?.addressPlace}</div>
        </div>
      </div>

      <div className="indexpage__heading">
        <div className="footer__address">
          <a href={`tel:${contact.phonenumber}`}>{contact.phonenumber} </a>
          <a href={`mailto:${contact.email}`}> {contact.email}</a>
          <a
            href={`https://instagram.com/${contact.instagram}`}
            target="_blank"
            rel="noreferrer"
          >
            @{contact.instagram}
          </a>
        </div>
        <Link
          to={galleryData.contactlink.url}
          className="indexpage__heading__contact-btn"
          dangerouslySetInnerHTML={{ __html: galleryData.contactlink.title }}
        />
      </div>
      <div className="indexpage__gallery" ref={gallery}>
        {galleryLists.map((galleryList, index) =>
          mobileView ? (
            <div>
              {index < 12 ? (
                <IndexBlock
                  imagePath={
                    galleryList.image.localFile.childImageSharp.original.src
                  }
                  address={galleryList}
                  setModalData={setModalData}
                  index={index}
                />
              ) : (
                <Fade bottom delay={(index % 6) * 50} key={index}>
                  <IndexBlock
                    imagePath={
                      galleryList.image.localFile.childImageSharp.original.src
                    }
                    address={galleryList}
                    setModalData={setModalData}
                    index={index}
                  />
                </Fade>
              )}
            </div>
          ) : (
            <Fade bottom delay={(index % 6) * 50} key={index}>
              <IndexBlock
                imagePath={
                  galleryList.image.localFile.childImageSharp.original.src
                }
                address={galleryList}
                setModalData={setModalData}
                index={index}
              />
            </Fade>
          )
        )}
      </div>
    </div>
  )
}

IndexPage.propTypes = {
  galleryLists: PropTypes.array,
}

export default IndexPage
